import './Loader.scss';
import { ReactComponent as LoaderIcon } from '../../img/loader/loader.svg';

export const Loader = () => {
  return (
    <div className="loader">
      <LoaderIcon width={70} height={70} />
    </div>
  );
};
