import React, { useEffect, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './App.scss';
import { Outlet, useLocation } from 'react-router-dom';
import { fetchCategory, fetchProducts } from './api/api';
import { Product } from './types/product';
import { Category } from './types/category';
import { convertKeysToLowerCase } from './helpers/convertKeysToLowerCase';
import { PARENT_FOLDER_ID } from './setting/setting';
import { ProductList } from './components/ProductList/ProductList';

import { Header } from './components/Header/Header';
import { Card } from './components/Card/Card';
import { fetchCity } from './api/npApi';
import { Order } from './components/Order/Order';
import { ShopProvider } from './ShopProvider';
import { productsFromServer } from './data/productsData';
import { Footer } from './components/Footer/Footer';

const App = () => {
  const [products, setProducts] = useState<Product[] | null>(null);
  const [category, setCategory] = useState<Category[] | null>(null);


  // useEffect(() => {
  //   fetchCity('боярка')
  //     .then((res) => (
  //       console.log(res)
  //     ))
  // }, []);

  // useEffect(() => {
  // fetchCategory()
  //   .then((res) => {
  //     const data = res.data;

  //     const newData: Category[] = convertKeysToLowerCase(data);
  //     const newCategorys = newData.filter(cat => cat.parentFolder === PARENT_FOLDER_ID);

  //     setCategory(newCategorys);
  //   });

  //If we fatch products

  // fetchProducts()
  //   .then((res) => {
  //     console.log(res);
  //     const data = res.data;

  //     const newProducts: Product[] = convertKeysToLowerCase(data);

  //     setProducts(newProducts);
  //   })

  // If we use data products

  // const data = productsFromServer;

  // const newProducts: Product[] = convertKeysToLowerCase(data);

  // setProducts(newProducts);
  // }, []);

  // const actualProducts = fetchProducts();

  // console.log(category);
  // console.log(actualProducts);
  // console.log(products);

  return (
    <ShopProvider>
      <div className="app">
        <Header />
        <main className="main">
          <div id="overlay" className="overlay"></div>
          <Outlet />
          {/* <Order /> */}
          {/* <Card /> */}
          {/* {products !== null && <ProductList products={products} />} */}
        </main>
        {/* <Footer /> */}
      </div>
    </ShopProvider>
  );
}

export default App;
