export const getNormalizePhone = (phone: string) => {
  switch(phone.length) {
    case 9:
      return `+380${phone}`;
    case 10:
      return `+38${phone}`;
    case 11:
      return `+3${phone}`;
    case 12:
      return `+${phone}`;
    default:
      return phone;
  }
}