import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Product } from "../../types/product";
import { ProductCard } from "../ProductCard/ProductCard";
import './ProductList.scss';

type Props = {
  products: Product[];
  isEnter?: boolean;
  isExit?: boolean;
};

export const ProductList: React.FC<Props> = ({
  products,
  isEnter = false,
  isExit = true,
}) => {
  return (
    <TransitionGroup className="products">
      {products.map((product) => (
        <CSSTransition
          key={product.id}
          timeout={300}
          classNames="products__item"
          enter={isEnter}
          exit={isExit}
        >
          <ProductCard product={product} key={product.id} />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
}