import React, { useContext } from 'react';
import { Product } from '../../types/product';
import { getMainImage } from '../../helpers/getMainImage';
import { getMainPrice } from '../../helpers/getMainPrice';
import { MAIN_CURRENCY } from '../../setting/setting';

import { ReactComponent as Cross } from '../../img/icons/cross.svg';
import { ReactComponent as Minus } from '../../img/icons/minus.svg';
import { ReactComponent as Plus } from '../../img/icons/plus.svg';
import { ShopContext } from '../../ShopContext';

type Props = {
  item: Product;
};

export const CartItem: React.FC<Props> = ({ item }) => {
  const { removeFromCart, changeValueInCart } = useContext(ShopContext);

  const mainImage = getMainImage(item.images);
  const mainPrice = getMainPrice(item.prices)?.Price;

  const handleCrossClick = () => {
    removeFromCart(item.id);
  };

  return (
    <div className="cart__item">
      <img
        src={mainImage.Url}
        alt={item.name}
        className="cart__item-img"
      />
      <p className="cart__item-name">{item.name}</p>
      <span className="cart__item-price">{`${mainPrice} ${MAIN_CURRENCY}`}</span>
      <div className="cart__item-counter">
        <span className="cart__item-count">{item.quantity || 1}</span>
        <div className="cart__item-counter-buttons">
          <button
            className="cart__item-counter-button"
            onClick={() => {
              changeValueInCart('+', item.id)
            }}
          >
            <Plus width="100%" height="100%" />
          </button>
          <button
            className="cart__item-counter-button"
            onClick={() => {
              changeValueInCart('-', item.id)
            }}
          >
            <Minus width="100%" height="100%" />
          </button>
        </div>
      </div>
      <button className="cart__item-remove" onClick={handleCrossClick}>
        <span className="cart__item-remove-ico">
          <Cross width="100%" height="100%" />
        </span>
      </button>
    </div>
  )
}