import './Card.scss';
import React from 'react';

import { Gallery } from '../Gallery/Gallery';
import { Product } from '../../types/product';
import { MAIN_CURRENCY, MAIN_PRICE_ID } from '../../setting/setting';
import { ButtonAddToCart } from '../ButtonAddToCart/ButtonAddToCart';

import { ButtonBack } from '../ButtonBack/ButtonBack';
import { ButtonAddToFavourites } from '../ButtonAddToFavourites/ButtonAddToFavourites';

type Props = {
  product: Product;
}

export const Card: React.FC<Props> = ({ product }) => {
  const mainPrice = product.prices.find((price) => price.PriceType === MAIN_PRICE_ID)?.Price;
  const description = product.description || '';

  return (
    <div className="card">
      <div className="card__top">
        <ButtonBack />
        <div className="card__id">{`ID: ${product.id}`}</div>
      </div>
      <Gallery images={product.images} name={product.name} key={product.id} />
      <h2 className="card__title">{product.name}</h2>
      <div className="card__description" dangerouslySetInnerHTML={{ __html: description }}>
      </div>
      <div className="card__actions">
        <span className="card__price">{`${mainPrice || ''} ${MAIN_CURRENCY}`}</span>
        <div className="card__buttons">
          <ButtonAddToFavourites classList={['card__fav']} productId={product.id} />
          <ButtonAddToCart classList={['card__button', 'button--card']} productId={product.id} />
        </div>
      </div>
    </div>
  );
};
