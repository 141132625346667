import axios from 'axios';
import { ORDERING, MAIN_PRICE_ID } from '../setting/setting';

const API_TOKEN = 'ApiKey splP8JCi1YzT9Te2kQmjGSJ5hodEpOxtCX00YidLpth8jEH9f9/z3WEkWwgUIl';
const PRODUCT_FILTER = {
  "filter": {
    "ParentFolder": 70563,
    "Store": [
      113
    ],
  }
};

export const fetchProducts = async (folder) => {
  const filter = folder ? {
    "filter": {
      "ParentFolder": folder,
      "Store": [
        113
      ],
    }
  }
    : {};

  try {
    const response = await axios.post('/api/product/list', filter,
      {
        headers: {
          Authorization: `${API_TOKEN}`,
          'Content-Type': 'application/json',
        }
      });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCategory = async () => {
  try {
    const response = await axios.post('/api/category', {}, {
      headers: {
        Authorization: `${API_TOKEN}`,
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAddParnter = async ({
  name,
  deliveryAddress,
  phone,
  email,
  payment,
}) => {
  const body = {
    "array": [
      {
        "Name": name,
        "ParentFolder": ORDERING.MAIN_PARTNER_FOLDER_ID,
        "DeliveryAddress": deliveryAddress,
        "Phone": phone,
        "Email": email,
        "Memo": payment
      }
    ]
  };

  try {
    const response = await axios.post('/api/partner/add', body,
      {
        headers: {
          Authorization: `${API_TOKEN}`,
          'Content-Type': 'application/json',
        }
      });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchGetContract = async (partnerId) => {
  const body = {
    "filter": {
        "Partner": partnerId,
    },
};

  try {
    const response = await axios.post('/api/contract/list', body,
      {
        headers: {
          Authorization: `${API_TOKEN}`,
          'Content-Type': 'application/json',
        }
      });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAddOrder = async ({
  payment,
  partnerId,
  contractId,
}, {
  date,
  sum,
}, products) => {
  const body = {
    "strict": true,
    "array": [
        {
            "Comment": payment,
            "Date": date,
            "Sum": sum,
            "Partner": partnerId,
            "Store": ORDERING.BASE_STORE_ID,
            "Status": ORDERING.NEW_ORDER_STATUS,
            "Contract": contractId,
            "PriceType": MAIN_PRICE_ID,
            "Responsible": ORDERING.USER_ADMIN_ID,
            "Rows": products
        }
    ]
};

  try {
    const response = await axios.post('/api/order/add', body,
      {
        headers: {
          Authorization: `${API_TOKEN}`,
          'Content-Type': 'application/json',
        }
      });
    return response.data;
  } catch (error) {
    throw error;
  }
};
