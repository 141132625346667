const PARENT_FOLDER_ID = 70562;
const MAIN_PRICE_ID = 347;
const DISCOUNT_PRICE_ID = 493;
const MAIN_CURRENCY = 'грн';
const BASE_DELAY = 1000;
const BASE_DELAY_PAGE_LOAD = 2000;
const BASE_BUTTON_ADD_TO_CART_PHRASE = 'Купити';
const ALTERNATIVE_BUTTON_ADD_TO_CART_PHRASE = 'В кошику';
const BASE_PRODUCTS_URL = 'products';

// ORDERING
const ORDERING = {
  MAIN_PARTNER_FOLDER_ID: 25760,
  CONTRACT_COMPANY_ID: 281,
  NEW_ORDER_STATUS: 5161,
  USER_ADMIN_ID: 3375,
  BASE_STORE_ID: 365,
  FREE_DELIVERY_COAST: 1000,
}

// SEARCH
const SEARCH_PHRASE = 'Шукати товар...';
const SEARCH_NO_RESULTS = 'Нічого не знайшлося...';
const SEARCH_MORE_PRODUCTS = 'Показати інші результати';
const SEARCH_PAGE_TITLE = 'Результати пошуку за:';

// IMAGE

const DEFOULT_IMAGE_DATA = {
  Id: 1001,
  Name: 'Product',
  Url: 'https://qfinmain.blob.core.windows.net/segment5/productsimages/459feff7-3e3f-4e7e-9077-5473c2ce504b_zaspok_01.jpg',
}

// PAGES

const PAGES = {
  CART_TITLE: 'Кошик',
  CART_EMPTY: 'Ваш кошик порожній...',
  FAVOURITES_TITLE: 'Обрані товари',
  FAVOURITES_EMPTY: 'Список обраних товарів порожній...',
  BACK_TO_PRODUSCT: 'Повернутися до покупок',
};

// ERRORS

const ERRORS = {
  ADDRESS_NOT_LOAD: 'Не вдалося знайти населений пункт',
  STREET_NOT_LOAD: 'Не вдалось знайти вулицю',
  DEPARTMENT_NOT_LOAD: 'Не вдалося знайти відділення',
};


// http://localhost:3000/search?query=%D1%87%D0%B0%D1%88%D0%BA%D0%B0



export {
  PARENT_FOLDER_ID,
  MAIN_PRICE_ID,
  DISCOUNT_PRICE_ID,
  MAIN_CURRENCY,
  BASE_DELAY,
  BASE_DELAY_PAGE_LOAD,
  SEARCH_PHRASE,
  SEARCH_NO_RESULTS,
  SEARCH_MORE_PRODUCTS,
  BASE_BUTTON_ADD_TO_CART_PHRASE,
  ALTERNATIVE_BUTTON_ADD_TO_CART_PHRASE,
  BASE_PRODUCTS_URL,
  DEFOULT_IMAGE_DATA,
  SEARCH_PAGE_TITLE,
  PAGES,
  ERRORS,
  ORDERING
};
