import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import { fetchProducts } from '../api/api';
import { Loader } from '../components/Loader/Loader';
import { ProductList } from "../components/ProductList/ProductList";
import { convertKeysToLowerCase } from '../helpers/convertKeysToLowerCase';
import { getAllProductWithImage } from '../helpers/getAllProductsWithImage';
import { searchProducts } from '../helpers/searchProducts';
import { transliterate } from '../helpers/transliterate';
import { wait } from '../helpers/wait';
import { BASE_DELAY, SEARCH_PAGE_TITLE } from '../setting/setting';
import { Product } from "../types/product";

export const SearchResultsPage: React.FC = () => {
  const [foundProducts, setFoundProducts] = useState<Product[]>([])
  const [isLoading, setIsLoading] = useState(false);
  const [serchParams] = useSearchParams();

  const query = serchParams.get('query') || '';

  useEffect(() => {
    setIsLoading(true);
    wait(BASE_DELAY)
      .then(() => {
        fetchProducts()
          .then((res) => {
            const allProductsData = convertKeysToLowerCase(res.data);

            const allProducts = (getAllProductWithImage(allProductsData));

            const newFoundProducts = searchProducts(allProducts, query, 0);

            setFoundProducts(newFoundProducts);
          })
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, []);

  return (
    <div className="main__content">
      <div className="main__top">
        <h1 className="main__title title">{`${SEARCH_PAGE_TITLE} "${query.toUpperCase()}"`}</h1>
      </div>
      {(!isLoading && foundProducts.length > 0) && (<section className="main__list">
        <ProductList products={foundProducts} />
      </section>)}
      {(!isLoading && foundProducts.length <= 0) && (<section className="main__list">
        <p className="main__text">Не вдалося знайти товари за даним запитом</p>
        <Link to={'./'} className="main__home-link link">Повернутися на головну</Link>
      </section>)}
      {isLoading && <Loader />}
    </div>
  );
};
