import './Order.scss';
import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import cn from 'classnames';

import { ReactComponent as Search } from '../../img/icons/search.svg';
import { ReactComponent as Cross } from '../../img/icons/cross_new.svg';
import { fetchAddresses, fetchCity, fetchDepartments } from '../../api/npApi';
import { Location, Department, Address } from '../../types/location';

import { ReactComponent as Loader } from '../../img/loader/loader.svg';
import { ERRORS } from '../../setting/setting';
import { ShopContext } from '../../ShopContext';
import { getMainPrice } from '../../helpers/getMainPrice';
import { getTotalText } from '../../helpers/getTotalText';
import { useNavigate } from 'react-router-dom';
import { Payments } from '../../types/payments';
import { OrderPayments } from './OrderPayments';
import { ButtonOrdering } from '../ButtonOrdering/ButtonOrdering';
import { Notification } from '../Notification/Notification';
import { getNormalizePhone } from '../../helpers/getNormalizePhone';

export const Order: React.FC = () => {
  const { cart } = useContext(ShopContext);
  const navigate = useNavigate();

  const totalPrice = useMemo(() => (
    cart.map(p => (getMainPrice(p.prices)?.Price || 0) * (p.quantity || 1)).reduce((sum, p) => sum + p, 0)
  ), [cart]);

  const totalItems = useMemo(() => (
    cart.map(p => (p.quantity || 1)).reduce((sum, p) => sum + p, 0)
  ), [cart]);

  const totalText = getTotalText(totalItems);

  const handleBackLink = () => {
    navigate('../cart');
  };

  const phoneField = useRef<HTMLInputElement>(null);
  const nameField = useRef<HTMLInputElement>(null);
  const surnameField = useRef<HTMLInputElement>(null);
  const mailField = useRef<HTMLInputElement>(null);

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [mail, setMail] = useState('');
  const [buildingNumber, setBuildingNumber] = useState('');
  const [payment, setPayment] = useState<Payments>('onCard');
  const [isClientDataValid, setIsClientDataValid] = useState(false);

  const [notification, setNotification] = useState('');

  const [isDepartmentDelivery, setIsDepartmentDelivery] = useState(true);

  const [isLocationLoading, setIsLocationLoading] = useState(false)
  const [locationsError, setLocationsError] = useState('');
  const [locations, setLocations] = useState<Location[] | null>(null);
  const [location, setLocation] = useState<Location | null>(null);
  const [locationValue, setLocationValue] = useState('');

  const [isAddressLoading, setIsAddressLoading] = useState(false)
  const [addressesError, setAddressesError] = useState('');
  const [addresses, setAddresses] = useState<Address[] | null>(null);
  const [address, setAddress] = useState<Address | null>(null);
  const [addressValue, setAddressValue] = useState('');

  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false)
  const [departmentValue, setDepartmentValue] = useState('');
  const [department, setDepartment] = useState<Department | null>(null);
  const [departments, setDepartments] = useState<Department[] | null>(null);
  const [departmentError, setDepartmentError] = useState('');

  const isVisible = {
    locationCross: locationValue.length > 1 && !isLocationLoading,
    locationSearch: locationValue.length <= 0 && !isLocationLoading,
    departmentCross: departmentValue.length > 1 && !isDepartmentLoading,
    departmentSearch: departmentValue.length <= 0 && !isDepartmentLoading,
    addressCross: addressValue.length > 1 && !isAddressLoading,
    addressSearch: addressValue.length <= 0 && !isAddressLoading,
  };

  useEffect(() => {
    let timerLocation: NodeJS.Timeout;
    if (isLocationLoading) {
      const timerLocation = setTimeout(() => {
        setIsLocationLoading(false);
      }, 1000);
    }

    if (!isLocationLoading && locationValue.length > 2) {
      fetchCity(locationValue)
        .then((res) => {
          if (res.data.length > 0) {
            const locations = res.data[0].Addresses
              ? res.data[0].Addresses
              : [];

            if (locations.length <= 0) {
              setLocationsError(ERRORS.ADDRESS_NOT_LOAD);
              return;
            }
            setLocationsError('');
            setLocations(locations);
          } else {
            setLocationsError(ERRORS.ADDRESS_NOT_LOAD);
          }
        })
        .catch(() => {
          setLocationsError(ERRORS.ADDRESS_NOT_LOAD);
        })
    }

    return () => clearTimeout(timerLocation);
  }, [isLocationLoading]);

  useEffect(() => {
    let timerAddress: NodeJS.Timeout;
    if (isAddressLoading) {
      const timerAddress = setTimeout(() => {
        setIsAddressLoading(false);
      }, 1000);
    }

    if (!isAddressLoading && addressValue.length > 2 && location) {
      const isLocationAvilabibil = location.StreetsAvailability;

      if (!isLocationAvilabibil) {
        const newAddress: Address = {
          Present: addressValue,
          SettlementRef: '2452888-48852-as45',
        }
        setAddress(newAddress);
        return;
      }

      fetchAddresses(location.Ref, addressValue)
        .then((res) => {
          console.log(res);
          const data: Address[] = res.data[0].Addresses.length > 0
            ? res.data[0].Addresses
            : [];

          console.log(data);

          if (data.length <= 0) {
            setAddressesError(ERRORS.STREET_NOT_LOAD);
            setAddresses(null);
            return;
          }

          if (data.length >= 40) {
            return;
          }

          const newAdresses: Address[] = data;


          setAddressesError('');
          setAddresses(newAdresses);

        })
        .catch(() => {
          setAddressesError(ERRORS.STREET_NOT_LOAD);
        })
    }

    return () => clearTimeout(timerAddress);
  }, [isAddressLoading]);

  useEffect(() => {
    let timerDepartment: NodeJS.Timeout;
    if (isDepartmentLoading) {
      const timerDepartment = setTimeout(() => {
        setIsDepartmentLoading(false);
      }, 1000);
    }

    if (!isDepartmentLoading && departmentValue.length > 0 && location) {
      fetchDepartments(location.DeliveryCity, departmentValue)
        .then((res) => {
          const data: Department[] = res.data.length > 0 ? res.data : [];

          if (data.length <= 0) {
            setDepartmentError(ERRORS.DEPARTMENT_NOT_LOAD);
            setDepartments(null);
            return;
          }

          if (data.length >= 100) {
            return;
          }

          const newDepartments = data.map(dep => {
            return {
              Description: dep.Description || '',
              Number: dep.Number || '',
              ShortAddress: dep.ShortAddress || '',
            }
          })


          setDepartmentError('');
          setDepartments(newDepartments);

        })
        .catch(() => {
          setDepartmentError(ERRORS.DEPARTMENT_NOT_LOAD);
        })
    }

    return () => clearTimeout(timerDepartment);
  }, [isDepartmentLoading]);

  useEffect(() => {
    if (notification.length > 0) {
      const timerNotification = setTimeout(() => {
        setNotification('');
      }, 5000);

      return () => clearTimeout(timerNotification);
    }
  }, [notification.length])

  const getDepartments = () => {
    if (location) {
      fetchDepartments(location.DeliveryCity, departmentValue, 40)
        .then((res) => {
          const data: Department[] = res.data.length > 0 ? res.data : [];
          if (data.length <= 0) {
            setDepartmentError(ERRORS.DEPARTMENT_NOT_LOAD);
            setDepartments(null);
            return;
          }

          const newDepartments = data.map(dep => {
            return {
              Description: dep.Description || '',
              Number: dep.Number || '',
              ShortAddress: dep.ShortAddress || '',
            }
          })

          setDepartmentError('');
          setDepartments(newDepartments);
        })
    }
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }

  const handleSurnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSurname(e.target.value);
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  }

  const handleMailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMail(e.target.value);
  }

  const handleBuildingNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuildingNumber(e.target.value);
  }

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocationValue(e.target.value);
    setIsLocationLoading(true);

    if (e.target.value.length === 0) {
      resetCity();
    }
  }

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddressValue(e.target.value);
    setIsAddressLoading(true);


    if (e.target.value.length === 0) {
      setAddresses([]);
      setAddressesError('');
    }
  }

  const handleDepartmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDepartmentValue(e.target.value);
    setIsDepartmentLoading(true);


    if (e.target.value.length === 0) {
      setDepartments([]);
      setDepartmentError('');
      getDepartments();
    }
  }

  const resetDepartments = () => {
    if (departmentValue.length > 0) {
      setDepartmentValue('');
    }

    if (departments) {
      setDepartments(null);
    }

    if (department) {
      setDepartment(null);
    }

    if (departmentError) {
      setDepartmentError('');
    }

    if (isDepartmentLoading) {
      setIsDepartmentLoading(false);
    }
  };

  const resetAddresses = () => {
    if (addressValue.length > 0) {
      setAddressValue('');
    }

    if (addresses) {
      setAddresses(null);
    }

    if (address) {
      setAddress(null);
    }

    if (addressesError) {
      setAddressesError('');
    }

    if (isAddressLoading) {
      setIsAddressLoading(false);
    }

    if (buildingNumber) {
      setBuildingNumber('');
    }
  };

  const resetCity = () => {
    if (locationsError) {
      setLocationsError('');
    }

    if (locationValue.length > 0) {
      setLocationValue('');
    }

    if (locations) {
      setLocations(null);
    }

    if (location) {
      setLocation(null);
    }

    resetDepartments();
    resetAddresses();
  };

  const handleNameFieldBlur = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const value = e.target.value.trim();
    const length = value.length;

    const isValid = length > 1 && /^[a-zA-Zа-яА-Я]+$/.test(value);

    if (!isValid) {
      setIsClientDataValid(false);
      nameField.current?.classList.add('input--danger');
      setNotification('Введіть ваше "Ім\'я"');
      return;
    }

    nameField.current?.classList.remove('input--danger');
    setIsClientDataValid(true);
    setNotification('');
  }

  const handleSurnameFieldBlur = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const value = e.target.value.trim();
    const length = value.length;

    const isValid = length > 1 && /^[a-zA-Zа-яА-Я]+$/.test(value);

    if (!isValid) {
      setIsClientDataValid(false);
      surnameField.current?.classList.add('input--danger');
      setNotification('Введіть ваше "Прізвище"');
      return;
    }

    surnameField.current?.classList.remove('input--danger');
    setIsClientDataValid(true);
    setNotification('');
  }

  const handleEmailFieldBlur = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const value = e.target.value;
    const length = value.length;

    const isValid = length > 4 && (value.includes('@')) && (value.includes('.'));

    if (!isValid) {
      setIsClientDataValid(false);
      mailField.current?.classList.add('input--danger');
      setNotification('Введіть дійсну email адресу');
      return;
    }

    mailField.current?.classList.remove('input--danger');
    setIsClientDataValid(true);
    setNotification('');
  }

  const handlePhoneFieldBlur = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const value = e.target.value;
    const phoneLength = value.length;
    const isOnlyNumbers = /^[0-9+]+$/.test(value);

    if (phoneLength < 9 || !isOnlyNumbers) {
      setIsClientDataValid(false);
      phoneField.current?.classList.add('input--danger');
      setNotification('Введіть коректний номер телефону');
      return;
    }

    const isValid = (
      (phoneLength === 9 && /^\d+$/.test(value))
      || (phoneLength === 10 && value[0] === '0')
      || (phoneLength === 11 && value[0] === '8')
      || (phoneLength === 12 && value[0] === '3')
      || (phoneLength === 13 && value[0] === '+'));

    if (!isValid) {
      setIsClientDataValid(false);
      phoneField.current?.classList.add('input--danger');
      setNotification('Введіть коректний номер телефону');
      return;
    }

    phoneField.current?.classList.remove('input--danger');
    const normalizePhone = getNormalizePhone(value);
    setIsClientDataValid(true);
    setNotification('');
    setPhone(normalizePhone);
  }

  const isLocations = locations !== null;
  const isDepartments = departments && departments.length > 0;
  const isAddresses = addresses && addresses.length > 0;

  const deliveryAddress = useMemo(() => {
    const deliveryName = isDepartmentDelivery ? 'Доставка у відділення' : 'Доставка за адресою';
    const deliveryParams = isDepartmentDelivery
      ? `${deliveryName}: Населений пункт: ${location?.Present}, Відділення: ${department?.Description}`
      : `${deliveryName}: Населений пункт: ${location?.Present}, Вулиця: ${address?.Present}, Будинок: ${buildingNumber}`;

    return deliveryParams;
  }, [location, department, address, buildingNumber, isDepartmentDelivery])

  const newClient = useMemo(() => {
    return {
      firstName: name,
      lastName: surname,
      departmentDelivery: isDepartmentDelivery,
      deliveryAddress: deliveryAddress,
      phone: phone,
      email: mail,
      payment: payment,
    }
  }, [name, surname, phone, mail, location, department, address, payment, buildingNumber]);

  const newDelivery = useMemo(() => {
    return {
      isDepartmentDelivery,
      location: location?.Present || '',
      department: department?.Description,
      address: address?.Present,
      buildingNumber: +buildingNumber || 0,
    }
  }, [isDepartmentDelivery, location, department, address, buildingNumber])

  const isDeliveryData = Boolean(location && (isDepartmentDelivery ? department : (address && buildingNumber)));
  const isOrderingActive = Boolean(name && surname && phone && mail && isDeliveryData && isClientDataValid);

  return (
    <div className="order">
      <h1 className="order__title title">Оформити замовлення</h1>
      <div className="order__section">
        <h2 className="order__subtitle subtitle">Ваші контактні дані</h2>
        <Notification message={notification} onMessage={setNotification} />
        <div className="order__inputs">
          <input
            id="name"
            type="text"
            placeholder="Ім’я"
            className="order_input input"
            ref={nameField}
            value={name}
            onChange={handleNameChange}
            onBlur={handleNameFieldBlur}
          />
          <input
            id="surname"
            type="text"
            placeholder="Прізвище"
            className="order_input input"
            ref={surnameField}
            value={surname}
            onChange={handleSurnameChange}
            onBlur={handleSurnameFieldBlur}
          />
          <input
            id="phone"
            type="tel"
            placeholder="Телефон"
            className="order_input input"
            ref={phoneField}
            value={phone}
            onChange={handlePhoneChange}
            onBlur={handlePhoneFieldBlur}
          />
          <input
            id="email"
            type="email"
            placeholder="Email"
            className="order_input input"
            ref={mailField}
            value={mail}
            onChange={handleMailChange}
            onBlur={handleEmailFieldBlur}
          />
        </div>
      </div>
      <div className="order__section">
        <h2 className="order__subtitle subtitle">Доставка Новою Поштою</h2>
        <div className="order__select">
          <button
            className={cn(
              'order_payment button button--payment',
              { 'button--payment--active': isDepartmentDelivery }
            )}
            onClick={() => {
              setIsDepartmentDelivery(true);
            }}
          >
            У Відділення
          </button>
          <button
            className={cn(
              'order_payment button button--payment',
              { 'button--payment--active': !isDepartmentDelivery }
            )}
            onClick={() => {
              setIsDepartmentDelivery(false);
            }}
          >
            За адресою
          </button>
        </div>
        <div className="order__inputs">
          <div id="user-location" className="order__inputs-container">
            <input
              id="location"
              type="text"
              placeholder="Місто"
              className="order__input input"
              value={locationValue}
              onChange={handleCityChange}
            />
            <button
              className="order__search"
              onClick={resetCity}
            >
              {isLocationLoading && <Loader width={'100%'} height={'100%'} />}
              {isVisible.locationSearch && <Search width={'100%'} height={'100%'} />}
              {isVisible.locationCross && <Cross width={'100%'} height={'100%'} />}
            </button>
            <div className={
              cn('order__search-results', {
                'order__search-results--active': isLocations || locationsError
              })}
            >
              {locationsError.length > 0 && (<div
                className="order__search-item"
              >
                {locationsError}
              </div>)}
              {locations?.map((item) => {
                const handleItemClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                  e.preventDefault();

                  setLocation(item);
                  setLocationValue(item.Present);
                  setLocations([]);
                }

                return (
                  <a
                    href="/#"
                    className="order__search-item"
                    key={item.Ref}
                    onClick={handleItemClick}
                  >
                    {item.Present}
                  </a>
                )
              })}
            </div>
          </div>
          {isDepartmentDelivery
            ? (<div id="user-department" className="order__inputs-container">
              <input
                disabled={!location}
                id="department"
                type="text"
                placeholder="Відділення"
                className={cn('order__input input', {
                  'input--disabled': !location
                })}
                value={departmentValue}
                onChange={handleDepartmentChange}
                onFocus={() => {
                  getDepartments();
                }}
              />
              <button
                className={cn('order__search', {
                  'order__search--hidden': !location
                })}
                onClick={resetDepartments}
              >
                {(isVisible.departmentSearch)
                  && <Search width={'100%'} height={'100%'} />}
                {isDepartmentLoading && <Loader width={'100%'} height={'100%'} />}
                {isVisible.departmentCross && <Cross width={'100%'} height={'100%'} />}
              </button>
              <div className={
                cn('order__search-results', {
                  'order__search-results--active': isDepartments || departmentError
                })}
              >
                {departmentError.length > 0 && (<div
                  className="order__search-item"
                >
                  {departmentError}
                </div>)}
                {departments?.map((item) => {
                  const handleItemClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                    e.preventDefault();

                    setDepartment(item);
                    setDepartmentValue(item.Description);
                    setDepartments([]);
                  }

                  return (
                    <a
                      href="/#"
                      className="order__search-item"
                      key={item.Number}
                      onClick={handleItemClick}
                    >
                      {item.Description}
                    </a>
                  )
                })}
              </div>
            </div>)
            : (<div id="user-address" className="order__inputs-container">
              <input
                disabled={!location}
                id="address"
                type="text"
                placeholder="Вулиця"
                className={cn('order__input input', {
                  'input--disabled': !location
                })}
                value={addressValue}
                onChange={handleAddressChange}
              />
              <button
                className={cn('order__search', {
                  'order__search--hidden': !location
                })}
                onClick={resetAddresses}
              >
                {(isVisible.addressSearch)
                  && <Search width={'100%'} height={'100%'} />}
                {isAddressLoading && <Loader width={'100%'} height={'100%'} />}
                {isVisible.addressCross && <Cross width={'100%'} height={'100%'} />}
              </button>
              <div className={
                cn('order__search-results', {
                  'order__search-results--active': isAddresses || addressesError
                })}
              >
                {addressesError.length > 0 && (<div
                  className="order__search-item"
                >
                  {addressesError}
                </div>)}
                {addresses?.map((item) => {
                  const handleItemClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                    e.preventDefault();

                    setAddress(item);
                    setAddressValue(item.Present);
                    setAddresses([]);
                  }

                  return (
                    <a
                      href="/#"
                      className="order__search-item"
                      key={item.SettlementRef}
                      onClick={handleItemClick}
                    >
                      {item.Present}
                    </a>
                  )
                })}
              </div>
            </div>)}
          {!isDepartmentDelivery && (<input
            disabled={!location}
            id="building"
            type="number"
            placeholder="Номер будинку"
            className={cn('order__input input', {
              'input--disabled': !location
            })}
            value={buildingNumber}
            onChange={handleBuildingNumberChange}
          />)}
        </div>
      </div>
      <OrderPayments payment={payment} onPayment={setPayment} />
      <div className="order__action">
        <div className="order__total">
          <p className="order__total-info">{`Усього ${totalItems} ${totalText} на суму ${totalPrice} грн`}</p>
        </div>
        <ButtonOrdering client={newClient} isButtonActive={isOrderingActive} sum={+totalPrice} delivery={newDelivery} />
        <button
          className="order__button-back link"
          onClick={handleBackLink}
        >
          Повернутися до кошика
        </button>
      </div>
    </div>
  );
};
