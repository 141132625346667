import './Social.scss';
import cn from 'classnames';
import { ReactComponent as Instagram } from '../../img/icons/social/instagram.svg';
import { ReactComponent as Telegram } from '../../img/icons/social/telegram.svg';
import { ReactComponent as Facebook } from '../../img/icons/social/facebook.svg';
import { ReactComponent as InstagramWhite } from '../../img/icons/social/instagram_white.svg';
import { ReactComponent as TelegramWhite } from '../../img/icons/social/telegram_white.svg';
import { ReactComponent as FacebookWhite } from '../../img/icons/social/facebook_white.svg';

type Props = {
  isFooter: boolean;
}

export const Social: React.FC<Props> = ({ isFooter }) => {
  return (
    <div className="social">
      <a
        target="_blank"
        href="https://instagram.com/"
        className={cn('social__icon', { 'social__icon--footer': isFooter })}
      >
        {!isFooter
        ? <Instagram width={"100%"} height={"100%"} />
        : <InstagramWhite width={"100%"} height={"100%"} />}
      </a>
      <a
        target="_blank"
        href="https://facebook.com/"
        className={cn('social__icon', { 'social__icon--footer': isFooter })}
      >
        {!isFooter 
        ? <Facebook width={"100%"} height={"100%"} />
        : <FacebookWhite width={"100%"} height={"100%"} />}
      </a>
      <a
        target="_blank"
        href="https://telegram.com/"
        className={cn('social__icon', { 'social__icon--footer': isFooter })}
      >
        {!isFooter 
        ? <Telegram width={"100%"} height={"100%"} />
        : <TelegramWhite width={"100%"} height={"100%"} />}
      </a>
    </div>
  );
};
