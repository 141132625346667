import React, { useMemo } from 'react';
import { api } from './api/client';
import { LocalStorageKeys } from './data/localStorageKeys';
import { useLocalStorage } from './hooks/useLocalStorage';
import { ShopContext } from './ShopContext';
import { Order } from './types/order';
import { ChangeType, Product } from './types/product';

type Props = {
  children: React.ReactNode;
};

export const ShopProvider: React.FC<Props> = ({ children }) => {
  const [cart, setCart] = useLocalStorage<Product[]>(LocalStorageKeys.cart, []);
  const [favourites, setFavourites] = useLocalStorage<Product[]>(LocalStorageKeys.favourites, []);
  const [order, setOrder] = useLocalStorage<Order | null>(LocalStorageKeys.order, null);

  const addToCart = (id: number): void => {
    api.products.find(id)
      .then((res) => {
        if (res) {
          const productToAdd: Product = { ...res };

          productToAdd.quantity = 1;

          const newCart = [...cart, productToAdd];

          setCart(newCart);
        };
      })
  };

  const removeFromCart = (id: number) => {
    const newCart = cart.filter(p => p.id !== id);

    setCart(newCart);
  }

  const changeValueInCart = (type: ChangeType, id: number) => {
    const product = cart.find(p => p.id === id);
    const cartWithoutProduct = cart.filter(p => p.id !== id);

    if (product) {
      const newProduct = { ...product };

      if (type === '+') {
        newProduct.quantity = newProduct.quantity
          ? newProduct.quantity + 1
          : 1;
      }

      if (type === '-') {
        newProduct.quantity = newProduct.quantity
          ? newProduct.quantity - 1
          : 1;
      }

      const newCart = [...cartWithoutProduct, newProduct];

      setCart(newCart);
    }
  };

  const clearCart = () => {
    setCart([]);
  };

  const addOrder = (newOrder: Order) => {
    setOrder(newOrder);
  };

  const addToFavourites = (id: number): void => {
    api.products.find(id)
      .then((res) => {
        if (res) {
          const productToAdd: Product = { ...res };

          productToAdd.quantity = 1;

          const newFavourites = [...favourites, productToAdd];

          setFavourites(newFavourites);
        };
      })
  };

  const removeFromFavourites = (id: number) => {
    const newFavourites = favourites.filter(p => p.id !== id);

    setFavourites(newFavourites);
  };

  const state = useMemo(() => ({
    cart,
    favourites,
    order,
    addToCart,
    addToFavourites,
    removeFromCart,
    changeValueInCart,
    removeFromFavourites,
    clearCart,
    addOrder,
  }), [cart, favourites, order]);

  return (
    <ShopContext.Provider value={state}>
      {children}
    </ShopContext.Provider>
  );
};
