import React, { useState } from 'react';
import cn from 'classnames';
import { useSwipeable } from 'react-swipeable';
import { ProductImage } from '../../types/product';
import './Gallery.scss';
const TEST_IMG_URL = "https://qfinmain.blob.core.windows.net/segment5/productsimages/7cddc57d-d09c-40bc-8c42-9771eff49e93_їбануті не старіють.jpg";

type Props = {
  images: ProductImage[];
  name: string;
}

export const Gallery: React.FC<Props> = ({ images, name }) => {
  const [mainImage, setMainImage] = useState(images[0]);
  const isThumbnails = images.length > 1;

  const handlerSwipe = useSwipeable({
    onSwipedLeft: () => {
      const currentIndex = images.findIndex(img => img.Id === mainImage.Id);
      const newIndex = currentIndex + 1 >= images.length ? 0 : currentIndex + 1;
      setMainImage(images[newIndex]);
    },
    onSwipedRight: () => {
      const currentIndex = images.findIndex(img => img.Id === mainImage.Id);
      const newIndex = currentIndex - 1 < 0 ? images.length - 1 : currentIndex - 1;
      setMainImage(images[newIndex]);
    },
    trackMouse: true,
    delta: 20,
  })

  return (
    <div className="card__gallery gallery">
      <div className="gallery__main" {...handlerSwipe}>
        <img className="gallery__img" src={mainImage.Url} alt={name} />
      </div>
      {isThumbnails && (<div className="gallery__thumbnails">
        {images.map(img => {
          const isThumbActive = img.Url === mainImage.Url;
          return (
            <img
              key={img.Id}
              className={cn('gallery__tbn', { 'gallery__tbn--active': isThumbActive })}
              src={img.Url}
              alt={name}
              onClick={() => {
                setMainImage(img);
              }}
            />
          )
        })}
      </div>)}
    </div>
  );
};
