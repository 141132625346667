import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { fetchProducts } from "../api/api";
import { Card } from "../components/Card/Card";
import { Loader } from "../components/Loader/Loader";
import { productsFromServer } from "../data/productsData";
import { convertKeysToLowerCase } from "../helpers/convertKeysToLowerCase";
import { findProductAtUrl } from "../helpers/findProductAtUrl";
import { wait } from "../helpers/wait";
import { BASE_DELAY } from "../setting/setting";
import { Product } from "../types/product";

export const ProductPage = () => {
  const { pathname } = useLocation();
  const link = pathname.slice(1);

  const mainLink = link.split('/').slice(1).join();

  const [product, setProduct] = useState<Product | null>(null);
  const [isLoading, setIsLoading] = useState(false);



  useEffect(() => {
    setIsLoading(true);
    wait(BASE_DELAY)
      .then(() => {
        fetchProducts()
          .then((res) => {
            const products = convertKeysToLowerCase(res.data);
            const newProduct = findProductAtUrl(products, mainLink);
            setProduct(newProduct);
          })
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, []);

  return (
    <div className="main__content">
      {!isLoading && product && <Card product={product} />}
      {isLoading && <Loader />}
    </div>
  );
}