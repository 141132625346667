import { Search } from '../Search/Search';
import { Social } from '../Social/Social';
import './Nav.scss';

import { ReactComponent as Time } from '../../img/icons/time.svg';
import { useEffect, useState } from 'react';
import { CategoryData } from '../../types/category';
import { categoryData } from '../../data/categoryData';
import { MenuItem } from '../MenuItem/MenuItem';
import { fetchProducts } from '../../api/api';
import { convertKeysToLowerCase } from '../../helpers/convertKeysToLowerCase';
import { Product } from '../../types/product';
import { getAllProductWithImage } from '../../helpers/getAllProductsWithImage';


const categories = categoryData;
const mainCategories = categories.filter(cat => cat.parentId === null);

type Props = {
  closeMenu: () => void;
}

export const Nav: React.FC<Props> = ({ closeMenu }) => {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    fetchProducts()
      .then((res) => {
        const newProducts = convertKeysToLowerCase(res.data);

        setProducts(getAllProductWithImage(newProducts));
      })
  }, [])

  return (
    <nav className="nav">
      <div className="nav__search">
        <Search products={products} closeMenu={closeMenu} />
      </div>
      <section className="nav__section">
        <ul className="nav__list">
          {mainCategories.map((cat) => {
            let subCategories: CategoryData[] = [];
            const isAnyChild = categories.some(c => c.parentId === cat.id);
            if (isAnyChild) {
              subCategories = categories.filter(c => c.parentId === cat.id);
            };

            return (
              <MenuItem
                category={cat}
                subCategories={subCategories}
                closeMenu={closeMenu}
                key={cat.id}
              />
            )
          })}
        </ul>
      </section>
      <section className="nav__section">
        <ul className="nav__pages-list">
          <li className="nav__pages-item">
            <a href="" className="nav__pages-link">Про нас</a>
          </li>
          <li className="nav__pages-item">
            <a href="" className="nav__pages-link">Доставка</a>
          </li>
          <li className="nav__pages-item">
            <a href="" className="nav__pages-link">Оплата</a>
          </li>
          <li className="nav__pages-item">
            <a href="" className="nav__pages-link">Конфіденційність</a>
          </li>
          <li className="nav__pages-item">
            <a href="" className="nav__pages-link">Договір оферти</a>
          </li>
          <li className="nav__pages-item">
            <a href="" className="nav__pages-link">Корпоративні замовлення</a>
          </li>
        </ul>
      </section>
      <section className="nav__info">
        <div className="nav__info-title">Контакти</div>
        <div className="nav__social">
          <Social isFooter={false} />
        </div>
        <a href="tel:+380988888888" className="nav__info-link">+380988888888</a>
        <a href="mailto:info@censored.com.ua" className="nav__info-link">info@censored.com.ua</a>

        <span className="nav__info-item">
          <Time width={15} height={15} /> &nbsp;
          Пн-Пт - З 10:00 до 19:00</span>
      </section>
    </nav>
  );
};
