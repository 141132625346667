import './Search.scss';
import cn from 'classnames';

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

import { ReactComponent as SearchIcon } from '../../img/icons/search.svg';
import { ReactComponent as Loader } from '../../img/loader/loader.svg';
import { ReactComponent as Cross } from '../../img/icons/cross_new.svg';
import { useEffect, useState } from 'react';
import { productsFromServer } from '../../data/productsData';
import { Product } from '../../types/product';
import { BASE_DELAY, SEARCH_MORE_PRODUCTS, SEARCH_NO_RESULTS, SEARCH_PHRASE } from '../../setting/setting';
import { searchProducts } from '../../helpers/searchProducts';
import { SearchItem } from './SearchItem';
import { wait } from '../../helpers/wait';
import { Link, useSearchParams } from 'react-router-dom';
import { getSearchWith } from '../../helpers/searchHelper';

type Props = {
  products: Product[];
  closeMenu: () => void;
}

export const Search: React.FC<Props> = ({
  products,
  closeMenu
}) => {
  const [query, setQuery] = useState('');
  const [foundProducts, setFoundProducts] = useState<Product[]>([]);
  const [isFoundProducts, setIsFoundProducts] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    if (query.length > 2) {
      setIsLoading(true);

      wait(BASE_DELAY)
        .then(() => {
          const newfoundProducts = searchProducts(products, query, 5);
          setFoundProducts(newfoundProducts);
          if (newfoundProducts.length < 1) {
            setIsFoundProducts(false);
          } else {
            setIsFoundProducts(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
    } else {
      setFoundProducts([]);
    }
  }, [query]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newQuery = e.target.value;
    setQuery(newQuery);

    const newParams = getSearchWith(searchParams, {
      query: newQuery || null,
    });

    setSearchParams(newParams);
  }

  const foundProductsLength = foundProducts.length > 4;

  return (
    <div className="search-container">
      <div className="search">
        <input
          type="text"
          className="search__input"
          placeholder={SEARCH_PHRASE}
          value={query}
          onChange={handleInputChange}
        />
        <button
          className="search__button"
          onClick={() => {
            if (foundProducts.length > 0 || query.length > 0) {
              setQuery('');
              setFoundProducts([]);
              setIsFoundProducts(true);

              const newParams = getSearchWith(searchParams, {
                query: null,
              });

              setSearchParams(newParams);
            }
          }}
        >
          {isLoading && (<Loader width={"100%"} height={"100%"} />)}
          {(!isLoading && foundProducts.length < 1 && query.length < 1) && (<SearchIcon width={"100%"} height={"100%"} />)}
          {((!isLoading && foundProducts.length > 0) || (!isLoading && query.length > 0)) && (<Cross width={"100%"} height={"100%"} />)}
        </button>
      </div>
      <div className={cn('results', { 'results--active': (foundProducts.length > 0 || !isFoundProducts) })}>
        <TransitionGroup className="results__list">
          {foundProducts.map((product) => (
            <CSSTransition
              key={product.id}
              classNames="results__transition"
              timeout={400}
            >
              <SearchItem product={product} key={product.id} />
            </CSSTransition>
          ))}
        </TransitionGroup>
        {foundProductsLength && (
          <Link
            to={`../search?${searchParams}`}
            className="results__all"
            onClick={() => {
              closeMenu();
            }}
          >
            {SEARCH_MORE_PRODUCTS}
          </Link>)}
        {!isFoundProducts && (<span className="results__emty">{SEARCH_NO_RESULTS}</span>)}
      </div>
    </div>
  );
};
