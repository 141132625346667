import './Header.scss';
import React, { useContext, useMemo, useState } from 'react';
import cn from 'classnames';

import { ReactComponent as Logo } from '../../img/logo/logo_censored.svg';
import { ReactComponent as Menu } from '../../img/icons/menu_new.svg';
import { ReactComponent as Cart } from '../../img/icons/cart_new.svg';
import { ReactComponent as Favourite } from '../../img/icons/favourite_empty_new.svg';
import { ReactComponent as FavouriteFill } from '../../img/icons/favourite_fill_new.svg';
import { ReactComponent as Cross } from '../../img/icons/cross_new.svg';
import { Nav } from '../Nav/Nav';
import { ShopContext } from '../../ShopContext';
import { Link, useNavigate } from 'react-router-dom';
import { scrollToTop } from '../../helpers/scrollToTop';

export const Header: React.FC = () => {
  const [isMenuActive, setIsMenuAcrtive] = useState(false);

  const { cart, favourites } = useContext(ShopContext);
  const cartLength = useMemo(() => {
    return cart.length;
  }, [cart]);
  const isFavourites = useMemo(() => {
    return favourites.length > 0;
  }, [favourites])

  const navigate = useNavigate();

  function updateBodyClass() {
    const body = document.querySelector('body');
    const overlay = document.querySelector('#overlay');
    if (!isMenuActive) {
      body?.classList.add('body--menu');
      overlay?.classList.add('overlay--active');
    } else {
      body?.classList.remove('body--menu');
      overlay?.classList.remove('overlay--active');
    };
  };

  const closeMenu = () => {
    setIsMenuAcrtive(false);
    updateBodyClass();
  };

  const goToPage = (page: string) => {
    navigate(`/${page}`);
    scrollToTop();
    if (isMenuActive) {
      closeMenu();
    }
  }

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const newValue = !isMenuActive;
    setIsMenuAcrtive(newValue);

    updateBodyClass();
  };

  return (
    <header className="header">
      <div className="header__top">
        <div className="header__section">
          <button
            className="header__button header__button--menu"
            onClick={handleMenuClick}
          >
            {!isMenuActive && <Menu width={'100%'} height={'100%'} />}
            {isMenuActive && <Cross width={'100%'} height={'100%'} />}
          </button>
          {/* <div className="header__button header__button--hidden"></div> */}
        </div>
        <Link to="/" className="header__logo">
          CENS
          <span className="header__logo--red">O</span>
          RED
          {/* <Logo width={'100%'} height={'100%'} /> */}
        </Link>
        <div className="header__section">
          <button
            className="header__button header__button--fav"
            onClick={() => {
              goToPage('favourites');
            }}
          >
            {isFavourites 
              ? <FavouriteFill width={'100%'} height={'100%'} />
              : <Favourite width={'100%'} height={'100%'} />
            }
          </button>
          <button
            className="header__button header__button--cart"
            onClick={() => {
              goToPage('cart');
            }}
          >
            {cartLength > 0 && (<div className="header__cart-info">
              <span className="header__cart-count">{cartLength}</span>
            </div>)}
            <Cart width={'100%'} height={'100%'} />
          </button>
        </div>
      </div>
      <div className={cn('header__menu', { 'header__menu--active': isMenuActive })}>
        <Nav closeMenu={closeMenu} />
      </div>
    </header>
  );
};
