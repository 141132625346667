import { Product } from "../types/product";
import { getMainPrice } from "./getMainPrice";

export const getProductsData = (products: Product[]): {
  "Product": number,
  "Price": number,
  "Qty": number,
  "Sum": number,
  "RowNo": number,
}[] => {
  let count = 1;
  const newProducts = products.map(product => {
    const price = getMainPrice(product.prices)?.Price || 0;
    const quantity = product.quantity || 1;
    const sum = price * quantity;

    const newProduct = {
      "Product": product.id,
      "Price": price,
      "Qty": quantity,
      "Sum": sum,
      "RowNo": count,
    }

    count++;

    return newProduct;
  });

  return newProducts;
};
