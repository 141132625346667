import axios from 'axios';

const API_TOKEN = 'Bearer 4e9f72a9c1e1f1056ecde5d80e458d9e8a6ecb94';
const BASE_URL = 'https://api.turbosms.ua';
const METHOD = 'message';
const FORMAT = 'send';
const SENDER = 'Censored';


export const fetchMessage = async (phones: string[], message: string) => {
  const fetchUrl = `${BASE_URL}/${METHOD}/${FORMAT}`;
  const body = {
    "recipients": phones,
    "sms": {
       "sender": SENDER,
       "text": message
    }
 }

  try {
    const response = await axios.post(fetchUrl, body,
      {
        headers: {
          Authorization: `${API_TOKEN}`,
          'Content-Type': 'application/json',
        }
      });
    return response.data;
  } catch (error) {
    throw error;
  }
};
