import React from "react";
import { Product } from "../../types/product";
import { MAIN_PRICE_ID } from "../../setting/setting";
import { transliterate } from "../../helpers/transliterate";

type Props = {
  product: Product;
};

export const SearchItem: React.FC<Props> = ({ product }) => {
  const price = product.prices
    .find(price => price.PriceType === MAIN_PRICE_ID)?.Price;

  const url = transliterate(product.name);
  const imgLink = product.images[0].Url || './';

  return (
    <a href={`../products/${url}`} className="results__item">
      <img src={imgLink} alt={product.name} className="results__img" />
      <div className="results__name">
        <span>{product.name}</span>
      </div>
      <div className="results__price">{`${price} грн`}</div>
    </a>
  );
};
