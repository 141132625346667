import { convertKeysToLowerCase } from "../helpers/convertKeysToLowerCase";
import { Product } from "../types/product";
import { fetchAddOrder, fetchAddParnter, fetchGetContract, fetchProducts } from "./api";
import { fetchMessage } from "./turboSmsApi";

export const api = {
  products: {
    get: (categoryId: number | string = '') => {
      return fetchProducts(categoryId)
        .then((res) => {
          const productsFromServer = res.data;
          return convertKeysToLowerCase(productsFromServer);
        })
        .catch((e) => {
          throw e;
        })
    },
    find: (id: number) => {
      return fetchProducts()
        .then((res) => {
          const productsFromServer: Product[] = convertKeysToLowerCase(res.data);
          const foundProduct = productsFromServer.find(p => p.id === id);

          return foundProduct;
        })
        .catch((e) => {
          throw e;
        })
    }  
  },
  client: {
    add: (partner: {
      name: string,
      deliveryAddress: string,
      phone: string,
      email: string,
      payment: string,
    }) => {
      return fetchAddParnter(partner)
        .then((res) => {
          return res;
        })
        .catch((e) => {
          throw e;
        })
    },
    getContract: (partnerId: number) => {
      return fetchGetContract(partnerId)
        .then((res) => {
          return res;
        })
        .catch((e) => {
          throw e;
        })
    }
  },
  message: {
    send: (phones: string[], message: string) => {
      return fetchMessage(phones, message)
        .then((res) => {
          return res;
        })
        .catch((e) => {
          throw e;
        })
    }
  },
  order: {
    add: (
      clientInfo: {
        payment: string,
        partnerId: number,
        contractId: number,
    },
    orderInfo: {
      date: string,
      sum: number,
    },
    products: {
      "Product": number,
      "Price": number,
      "Qty": number,
      "Sum": number,
      "RowNo": number,
    }[],
    ) => {
      return fetchAddOrder(clientInfo, orderInfo, products)
        .then((res) => {
          return res;
        })
        .catch((e) => {
          throw e;
        })
    }
  }
};
