import { CategoryData } from "../types/category";

export const categoryData: CategoryData[] = [
  {
    id: 101,
    parentId: null,
    fetchId: 70563,
    name: 'Чашки',
    link: 'cups',
  },
  {
    id: 102,
    parentId: null,
    fetchId: 120059,
    name: 'Листівки',
    link: 'postcards',
  },
  {
    id: 103,
    parentId: null,
    fetchId: 120058,
    name: 'Сумки',
    link: 'bags',
  },
  {
    id: 104,
    parentId: null,
    fetchId: 120057,
    name: 'Футболки',
    link: 't-shirts',
  },
  {
    id: 105,
    parentId: null,
    fetchId: 70562,
    name: 'Набори',
    link: 'sets',
  },
  {
    id: 106,
    parentId: 101,
    fetchId: 70562,
    name: 'Гумористичні',
    link: 'humorous',
  },
  {
    id: 107,
    parentId: 101,
    fetchId: 70562,
    name: 'Романтичні',
    link: 'romantic',
  },
  {
    id: 108,
    parentId: 101,
    fetchId: 70562,
    name: 'Патріотичні',
    link: 'patriotic',
  },
  {
    id: 109,
    parentId: 101,
    fetchId: 70562,
    name: 'Святкові',
    link: 'festive',
  },
  {
    id: 110,
    parentId: 101,
    fetchId: 70562,
    name: 'Сімейні',
    link: 'family',
  },
  {
    id: 111,
    parentId: 101,
    fetchId: 70562,
    name: 'Емоційні',
    link: 'emotional',
  },
  {
    id: 112,
    parentId: 102,
    fetchId: 70562,
    name: 'Гумористичні',
    link: 'humorous',
  },
  {
    id: 113,
    parentId: 102,
    fetchId: 70562,
    name: 'Романтичні',
    link: 'romantic',
  },
  {
    id: 114,
    parentId: 102,
    fetchId: 70562,
    name: 'Патріотичні',
    link: 'patriotic',
  },
  {
    id: 115,
    parentId: 102,
    fetchId: 70562,
    name: 'Святкові',
    link: 'festive',
  },
  {
    id: 116,
    parentId: 102,
    fetchId: 70562,
    name: 'Сімейні',
    link: 'family',
  },
  {
    id: 117,
    parentId: 102,
    fetchId: 70562,
    name: 'Емоційні',
    link: 'emotional',
  },
  {
    id: 118,
    parentId: 103,
    fetchId: 70562,
    name: 'Гумористичні',
    link: 'humorous',
  },
  {
    id: 119,
    parentId: 103,
    fetchId: 70562,
    name: 'Романтичні',
    link: 'romantic',
  },
  {
    id: 120,
    parentId: 103,
    fetchId: 70562,
    name: 'Патріотичні',
    link: 'patriotic',
  },
  {
    id: 121,
    parentId: 103,
    fetchId: 70562,
    name: 'Святкові',
    link: 'festive',
  },
  {
    id: 122,
    parentId: 103,
    fetchId: 70562,
    name: 'Сімейні',
    link: 'family',
  },
  {
    id: 123,
    parentId: 103,
    fetchId: 70562,
    name: 'Емоційні',
    link: 'emotional',
  },
  {
    id: 124,
    parentId: 104,
    fetchId: 70562,
    name: 'Гумористичні',
    link: 'humorous',
  },
  {
    id: 125,
    parentId: 104,
    fetchId: 70562,
    name: 'Романтичні',
    link: 'romantic',
  },
  {
    id: 126,
    parentId: 104,
    fetchId: 70562,
    name: 'Патріотичні',
    link: 'patriotic',
  },
  {
    id: 127,
    parentId: 104,
    fetchId: 70562,
    name: 'Святкові',
    link: 'festive',
  },
  {
    id: 128,
    parentId: 104,
    fetchId: 70562,
    name: 'Сімейні',
    link: 'family',
  },
  {
    id: 129,
    parentId: 104,
    fetchId: 70562,
    name: 'Емоційні',
    link: 'emotional',
  },
]