import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { api } from "../api/client";
import { ProductCardSlider } from "../components/ProductCardSlider/ProductCardSlider";
import { ProductSlider } from "../components/ProductSlider/ProductSlider";
import { getNewProducts } from "../helpers/getNewProducts";
import { Product } from "../types/product";

export const HomePage = () => {
  const [hits, setHits] = useState<Product[]>([]);
  const [searchParams] = useSearchParams();
  const sort = searchParams.get('sort') || '';

  useEffect(() => {
    api.products.get()
      .then((res) => {
        const newProducts: Product[] = res;

        setHits(getNewProducts(newProducts, 'hit', sort));
      })

  }, []);

  return (
    <div className="page">
      {/* <ProductSlider products={hits} name={'Хіти продажів'} /> */}
      <ProductCardSlider products={hits} />
    </div>
  );
};
