import './ButtonAddToCart.scss';

import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import { ALTERNATIVE_BUTTON_ADD_TO_CART_PHRASE, BASE_BUTTON_ADD_TO_CART_PHRASE } from '../../setting/setting';
import { ShopContext } from '../../ShopContext';


type Props = {
  classList: string[] | null;
  productId: number;
}

export const ButtonAddToCart: React.FC<Props> = ({
  classList,
  productId,
}) => {
  const classes = classList ? classList.join(' ') : '';
  const { cart, addToCart } = useContext(ShopContext);

  const isProductInCart = useMemo(() => {
    return cart.some(product => product.id === productId);
  }, [cart, productId]);
  
  const buttonText = isProductInCart
    ? ALTERNATIVE_BUTTON_ADD_TO_CART_PHRASE
    : BASE_BUTTON_ADD_TO_CART_PHRASE;

  const handleButtonClick = () => {
    if (!isProductInCart) {
      addToCart(productId);
    };
  };



  return (
    <button
      className={cn(`${classes} button button--main`,
        { 'button--main--added': isProductInCart })}
      onClick={handleButtonClick}
    >
      {buttonText}
    </button>
  );
};

