import {
  BrowserRouter as Router, Routes, Route, Navigate,
} from 'react-router-dom';

import App from './App';
import { CartPage } from './pages/CartPage';
import { CategoryPage } from './pages/CategoryPage';
import { FavouritesPage } from './pages/FavouritesPage';
import { HomePage } from './pages/HomePage';
import { OrderComfirmation } from './pages/OrderComfirmationPage';
import { OrderPage } from './pages/OrderPage';
import { ProductPage } from './pages/ProductPage';
import { SearchResultsPage } from './pages/SearcResultsPage';

export const Root = () => (
  <Router>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<HomePage />} />
        <Route path="home" element={<Navigate to="/" />} />
        <Route path="products">
          {/* <Route index element={<Phones />} /> */}
          <Route path=":productId" element={<ProductPage />} />
        </Route>
        <Route path="favourites">
          <Route index element={<FavouritesPage />} />
        </Route>
        <Route path="cart">
          <Route index element={<CartPage />} />
        </Route>
        <Route path="order">
          <Route index element={<OrderPage />} />
          <Route path="comfirmation" element={<OrderComfirmation />} />
        </Route>
        <Route path="search">
          <Route index element={<SearchResultsPage />} />
        </Route>
        <Route path="cups">
          <Route index element={<CategoryPage />} />
          <Route path=":subCategory" element={<CategoryPage />} />
        </Route>
        <Route path="postcards">
          <Route index element={<CategoryPage />} />
          <Route path=":subCategory" element={<CategoryPage />} />
        </Route>
        <Route path="bags">
          <Route index element={<CategoryPage />} />
          <Route path=":subCategory" element={<CategoryPage />} />
        </Route>
        <Route path="t-shirts">
          <Route index element={<CategoryPage />} />
          <Route path=":subCategory" element={<CategoryPage />} />
        </Route>
        <Route path="sets">
          <Route index element={<CategoryPage />} />
          <Route path=":subCategory" element={<CategoryPage />} />
        </Route>
      </Route>
    </Routes>
  </Router>
);
