import { MAIN_PRICE_ID } from "../setting/setting";
import { Product } from "../types/product";


export function getSortedProducts(
  sort: string, products: Product[],
): Product[] {
  switch (sort) {
    case 'id':
      return products.sort((a, b) => a.id - b.id);
    case 'price_cheap':
      return products.sort((a, b) => {
        const mainPriceA = a.prices.find(p => p.PriceType === MAIN_PRICE_ID)?.Price || 0;
        const mainPriceB = b.prices.find(p => p.PriceType === MAIN_PRICE_ID)?.Price || 0;

        return mainPriceA - mainPriceB;
      });
    case 'price_exp':
      return products.sort((a, b) => {
        const mainPriceA = a.prices.find(p => p.PriceType === MAIN_PRICE_ID)?.Price || 0;
        const mainPriceB = b.prices.find(p => p.PriceType === MAIN_PRICE_ID)?.Price || 0;

        return mainPriceB - mainPriceA;
      });
    case 'name':
      return products.sort((a, b) => a.name.localeCompare(b.name));
    default:
      return products;
  }
}