import React, { useContext, useState } from "react";
import cn from 'classnames';
import { api } from "../../api/client";
import { ShopContext } from "../../ShopContext";
import { getProductsData } from "../../helpers/getProductsData";
import { getStringDate } from "../../helpers/getStringDate";

import { ReactComponent as Loader } from '../../img/loader/loader.svg';
import { Order } from "../../types/order";
import { useNavigate } from "react-router-dom";

type Props = {
  client: {
    firstName: string;
    lastName: string;
    deliveryAddress: string;
    phone: string;
    email: string;
    payment: string;
  },
  delivery: {
    isDepartmentDelivery: boolean;
    location: string;
    department?: string;
    address?: string;
    buildingNumber: number;
  },

  sum: number;
  isButtonActive: boolean;
}

export const ButtonOrdering: React.FC<Props> = ({
  client,
  delivery,
  isButtonActive,
  sum,
}) => {
  const { cart, addOrder, clearCart } = useContext(ShopContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = async () => {
    if (!isButtonActive) {
      return;
    }

    setIsLoading(true);

    const partnerName = `${client.lastName} ${client.firstName}`;
    const payment = client.payment === 'onCard' ? 'Оплата Карткою' : 'Оплата переказом на ФОП';
    const partnerToAdd = {
      name: partnerName,
      deliveryAddress: client.deliveryAddress,
      phone: client.phone,
      email: client.email,
      payment: payment,
    };

    const productsData = getProductsData(cart);

    console.log(productsData);

    const contractName = `Контракт з сайту - ${partnerName}`;
    const date = getStringDate();

    try {
      const partnerResponse = await api.client.add(partnerToAdd);
      const clientId: number = +partnerResponse.data[0].id;
      console.log(clientId);

      const contractResponse = await api.client.getContract(clientId);
      const contractId: number = +contractResponse.data[0].Id;

      console.log(contractId);

      const orderResponse = await api.order.add(
        { payment, partnerId: clientId, contractId },
        { date, sum },
        productsData,
      );

      const orderId = orderResponse.data[0].Id;

      const smsMessage = `Привіт ${client.firstName}. Ми вже отримали твоє замовлення №${orderId} і скоро відправимо до тебе. Гарного дня!`;

      const messageResponse = await api.message.send([client.phone], smsMessage);

      const orderProducts = [...cart];

      const newOrder: Order = {
        id: orderId,
        payment: client.payment,
        client: {
          id: clientId,
          conractId: contractId,
          firstName: client.firstName,
          lastName: client.lastName,
          email: client.email,
          phone: client.phone,
        },
        delivery,
        products: orderProducts,
      }

      addOrder(newOrder);
      clearCart();
      setIsLoading(false);
      navigate('comfirmation');

    } catch (e) {
      console.log(e);
    }
  }

  return (
    <button
      disabled={!isButtonActive}
      className={cn('order__button button button--order',
        { 'button--order--disabled': !isButtonActive })}
      onClick={handleButtonClick}
    >
      {isLoading && <Loader width={30} heigth={30} />}
      {!isLoading && ('Оплатити та замовити')}
    </button>
  );
};
