import React, { useState } from 'react';
import cn from 'classnames';
import './Notification.scss';
import { ReactComponent as Cross } from '../../img/icons/cross_new.svg';


type Props = {
  message: string;
  onMessage: (v: string) => void;
}

export const Notification: React.FC<Props> = ({ message, onMessage }) => {
  const [active, setActive] = useState(true);
  return (
    <div className={cn('notification order__notification',
      { 'notification--active': message.length > 0 })}>
      <span className="notification__message">{message}</span>
      <button
        className="notification__button"
        onClick={() => {
          onMessage('');
        }}
      >
        <Cross width={'100%'} height={'100%'} />
      </button>
    </div>
  );
};
