import { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { OrderDetails } from "../components/OrderDetails/OrderDetails";
import { getMainPrice } from "../helpers/getMainPrice";
import { ORDERING } from "../setting/setting";
import { ShopContext } from "../ShopContext";

export const OrderComfirmation = () => {
  const { order } = useContext(ShopContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!order) {
      navigate('/');
    }
  }, [])

  const products = order?.products || [];
  const payment = order?.payment === 'onCard' ? 'карткою на сайті' : 'переказом на рахунок ФОП';
  const isPayOnline = order?.payment === 'onCard';

  const totalPrice = useMemo(() => (
    products.map(p => (getMainPrice(p.prices)?.Price || 0) * (p.quantity || 1)).reduce((sum, p) => sum + p, 0)
  ), [products]);

  const deliveryType = order?.delivery.isDepartmentDelivery ? 'у відділення Нової Пошти' : 'кур\'єром Нової пошти за адресою';


  return (
    <div className="page">
      <h1 className="page__title title">Підтвердження замовлення</h1>
      <section className="page__section">
        <p className="page__text text">
          {`Вітаємо, ${order?.client.firstName}! Ви успішно оформили замовлення №${order?.id} в магазині Censored.`}
        </p>
      </section>
      <section className="page__section">
        <h2 className="page__subtitle subtitle">Деталі замовлення</h2>
        <OrderDetails products={products} />
      </section>
      <section className="page__section">
        <h2 className="page__subtitle subtitle">Доставка</h2>
        <p className="page__text text">
          {`Ви обрали доставку ${deliveryType}.`}
        </p>
        <p className="page__text text text--bold">Деталі доставки:</p>
        <p className="page__text text">
          {` ${order?.delivery.location}.`}
        </p>
        <p className="page__text text">
          {order?.delivery.isDepartmentDelivery
            ? (`${order.delivery.department}`)
            : (`${order?.delivery.address} ${order?.delivery.buildingNumber}`)
          }
        </p>
        <p className="page__text text text--bold">
          {totalPrice >= ORDERING.FREE_DELIVERY_COAST && order?.delivery.isDepartmentDelivery
            ? ('Для вашого замовлення діє безкоштовна доставка.')
            : ('Нагадуємо, що доставка замовлееня оплачується окремо, при отриманні.')
          }
        </p>
      </section>
      <section className="page__section">
        <h2 className="page__subtitle subtitle">Оплата</h2>
        <p className="page__text text">{`Ви обрали оплату ${payment}.`}</p>
        {isPayOnline
          ? (
            <div className="page__container">
              <p className="page__text text">Для оплати замовлення перейдіть за посиланням</p>
              <button className="button button--order">Оплатити замовлення</button>
            </div>
          )
          : (
            <div className="page__container">
              <p className="page__text text">Для оплати замовлення здійсніть платіж за реквізитами.</p>
              <p className="page__text text">Рахунок: UA2584600086512077542311</p>
              <p className="page__text text">МФО: 22458</p>
              <p className="page__text text">Отримувач: ФОП Дудар Ярослав Романович</p>
              <p className="page__text text">{`Призначення платежу: Оплата замовлення №${order?.id}`}</p>
            </div>
          )}
      </section>
    </div>
  );
  ;
}
