import { useMemo } from 'react';
import { getMainImage } from '../../helpers/getMainImage';
import { getMainPrice } from '../../helpers/getMainPrice';
import { Product } from '../../types/product';
import './OrderDetails.scss';

type Props = {
  products: Product[],
}

export const OrderDetails: React.FC<Props> = ({ products }) => {
  const totalPrice = useMemo(() => (
    products.map(p => (getMainPrice(p.prices)?.Price || 0) * (p.quantity || 1)).reduce((sum, p) => sum + p, 0)
  ), [products]);

  const totalItems = useMemo(() => (
    products.map(p => (p.quantity || 1)).reduce((sum, p) => sum + p, 0)
  ), [products]);

  return (
    <table className="details">
      <thead className="details__thead">
        <tr className="details__row">
          <td className="details__cell"></td>
          <td className="details__cell">Назва товару</td>
          <td className="details__cell">Ціна, грн</td>
          <td className="details__cell" style={{ whiteSpace: 'nowrap' }}>{`К-ть, шт`}</td>
          <td className="details__cell">Сума, грн</td>
        </tr>
      </thead>
      <tbody className="details__products">
        {products.map(product => {
          const sum = (getMainPrice(product.prices)?.Price || 0) * (product.quantity || 1);
          return (
            <tr key={product.id} className="details__row">
              <td className="details__cell">
                <img className="details__img" src={getMainImage(product.images).Url} alt={product.name} />
              </td>
              <td className="details__cell">{product.name}</td>
              <td className="details__cell">{getMainPrice(product.prices)?.Price}</td>
              <td className="details__cell">{product.quantity}</td>
              <td className="details__cell">{sum}</td>
            </tr>
          )
        })}
      </tbody>
      <tfoot className="details__tfoot">
        <tr className="details__row">
          <td className="details__cell"></td>
          <td className="details__cell">Усього:</td>
          <td className="details__cell"></td>
          <td className="details__cell">{totalItems}</td>
          <td className="details__cell">{totalPrice}</td>
        </tr>
      </tfoot>
    </table>
  );
};
