import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import Select, {
  SingleValue,
} from 'react-select';
import { fetchProducts } from "../api/api";

import { Loader } from "../components/Loader/Loader";
import { ProductList } from "../components/ProductList/ProductList";
import { categoryData } from "../data/categoryData";
import { sortOptions } from "../data/sortOptions";
import { convertKeysToLowerCase } from "../helpers/convertKeysToLowerCase";
import { getNewProducts } from "../helpers/getNewProducts";
import { getSearchWith } from "../helpers/searchHelper";
import { wait } from "../helpers/wait";
import { BASE_DELAY, BASE_DELAY_PAGE_LOAD } from "../setting/setting";
import { Option } from "../types/options";
import { Product } from "../types/product";

export const CategoryPage = () => {
  const categories = categoryData;
  const { pathname } = useLocation();
  const link = pathname.slice(1);

  const mainLink = link.includes('/') ? link.split('/').slice(0, 1).join() : link;
  const subLink = link.includes('/') ? link.split('/').slice(1).join() : null;
  const category = categories.find(cat => cat.link === mainLink);
  const subCategory = categories.find(cat => cat.link === subLink);

  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const sort = searchParams.get('sort') || '';
  const options = sortOptions;
  const defaultSortOption = options.find(option => option.value === sort) || options[0];

  // If we use wait function adn dont wait when all img loading
  useEffect(() => {
    setIsLoading(true);

    wait(100)
      .then(() => {
        fetchProducts(category?.fetchId)
          .then((res) => {
            const allProducts = convertKeysToLowerCase(res.data) as Product[];

            setProducts(getNewProducts(allProducts, subLink, sort));
          })
      })
      .finally(() => {
        wait(BASE_DELAY_PAGE_LOAD)
         .finally(()=> {
            setIsLoading(false);
         })
      })
  }, [sort, subLink, mainLink]);

  const handleSortSelectChange = (opt: SingleValue<Option>) => {
    const currentValue: string = Object(opt).value || '';

    const newSort = getSearchWith(searchParams, {
      sort: currentValue || null,
    });

    setSearchParams(newSort);
  };

  const title = `${subCategory?.name || ''} ${category?.name}`;

  return (
    <div className="main__content">
      <div className="main__top">
        <h1 className="main__title title">{title}</h1>
        <div className="main__sort">
          <Select
            options={options}
            defaultValue={defaultSortOption}
            className="react-select"
            classNamePrefix="react-select"
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                minHeight: '30px',
              }),
              indicatorsContainer: (baseStyles) => ({
                ...baseStyles,
                padding: '1px',
              }),
              dropdownIndicator: (baseStyles) => ({
                ...baseStyles,
                padding: '2px',
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 4,
              colors: {
                ...theme.colors,
                primary25: '#F00C18',
                primary: 'black',
              },
            })}
            onChange={handleSortSelectChange}
          />
        </div>
      </div>
      {!isLoading && (<section className="main__list">
        <ProductList products={products} />
      </section>)}
      {isLoading && <Loader />}
    </div>
  );
}