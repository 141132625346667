import React, { useContext, useMemo } from 'react';
import './ButtonAddToFavourites.scss';

import { ReactComponent as FavouriteIcon } from '../../img/icons/favourite_empty_new.svg';
import { ReactComponent as FavouriteFill } from '../../img/icons/favourite_fill_new.svg';
import { ShopContext } from '../../ShopContext';

type Props = {
  classList: string[] | null;
  productId: number;
}

export const ButtonAddToFavourites: React.FC<Props> = ({
  classList,
  productId,
}) => {
  const {
    favourites,
    addToFavourites,
    removeFromFavourites
  } = useContext(ShopContext);

  const classes = classList ? classList.join(' ') : '';
  const isProductInFav = useMemo(() => {
    return favourites.some(product => product.id === productId);
  }, [favourites, productId]);

  const handleButtonClick = () => {
    if (!isProductInFav) {
      addToFavourites(productId);
    } else {
      removeFromFavourites(productId);
    }
  };

  return (
    <button className={`${classes} fav`} onClick={handleButtonClick}>
      {!isProductInFav 
        ? <FavouriteIcon width={'100%'} height={'100%'} />
        : <FavouriteFill width={'100%'} height={'100%'} />}
    </button>
  );
};
