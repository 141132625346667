import { Option } from "../types/options";

export const sortOptions: Option[] = [
  {
    value: 'def',
    label: 'За замовчуванням'
  },
  {
    value: 'name',
    label: 'За назвою'
  },
  {
    value: 'price_cheap',
    label: 'За ціною: спочатку дешевші'
  },
  {
    value: 'price_exp',
    label: 'За ціною: спочатку дорожчі'
  },
  {
    value: 'id',
    label: 'За артикулом'
  },
];
