import './ButtonBack.scss';

import { ReactComponent as ArrowLeft } from '../../img/icons/arrow_left.svg';
import { Link, useNavigate } from 'react-router-dom';


export const ButtonBack = () => {
  const navigate = useNavigate();

  const handleBackLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <Link to=".#" className="button-back" onClick={handleBackLink}>
      <ArrowLeft width={'100%'} height={'100%'} />
    </Link>
  );
};
