import React, { useState } from "react";
import { CategoryData } from "../../types/category";
import cn from 'classnames';
import { Link } from "react-router-dom";

type Props = {
  category: CategoryData;
  subCategories: CategoryData[];
  closeMenu: () => void;
}

export const MenuItem: React.FC<Props> = ({
  category,
  subCategories,
  closeMenu,
}) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  return (
    <>
      <li className="nav__item">
        <Link
          to={`/${category.link}`}
          className="nav__link"
          onClick={() => {
            closeMenu();
          }}
        >
          {category.name}
        </Link>
        {(subCategories.length > 0) && (<button
          className="nav__button"
          onClick={(e) => {
            e.preventDefault();
            setIsSubmenuOpen(!isSubmenuOpen);
          }}
        >
          {isSubmenuOpen ? '-' : '+'}
        </button>)}
      </li>
      {(subCategories.length > 0) && (
        <ul className={cn('nav__sublist', { 'nav__sublist--active': isSubmenuOpen })}>
          {subCategories.map((subCat) => {
            return (
              <li className="nav__subitem" key={subCat.id}>
                <Link
                  to={`/${category.link}/${subCat.link}`}
                  className="nav__sublink"
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  {subCat.name}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};
