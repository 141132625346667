export function getTotalText(totalItems: number) {
  if (totalItems === 1) {
    return 'товар';
  }

  if (totalItems > 1 && totalItems < 5) {
    return 'товари'
  }

  return 'товарів'
};
