import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonBack } from "../components/ButtonBack/ButtonBack";
import { ProductList } from '../components/ProductList/ProductList';
import { PAGES } from '../setting/setting';
import { ShopContext } from '../ShopContext';

export const FavouritesPage = () => {
  const { favourites } = useContext(ShopContext);

  const visibleProducts = useMemo(() => {
    return favourites.sort((a, b) => a.name.localeCompare(b.name));
  }, [favourites]);

  const isFavourites = favourites.length > 0;

  const navigate = useNavigate();

  const handleBackLink = () => {
    navigate(-1);
  };

  return (
    <div className="page">
      <div className="page__top">
        <div className="page__nav">
          <ButtonBack />
        </div>
        <h1 className="page__title title">{PAGES.FAVOURITES_TITLE}</h1>
        <div className="page__nav"></div>
      </div>
      <section className="page__section">
        {isFavourites
          ? (<ProductList products={visibleProducts} />)
          : (
            <div className="page__col page__col--center">
              <p className="page__text">{PAGES.FAVOURITES_EMPTY}</p>
              <button
                className="page__back link"
                onClick={handleBackLink}
              >
                {PAGES.BACK_TO_PRODUSCT}
              </button>
            </div>
          )
        }
      </section>
    </div>
  );
};
