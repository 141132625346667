import { Product } from "../types/product";
import { getSortedProducts } from "./getSortedProducts";

export function getNewProducts(
  products: Product[],
  subLink: string | null,
  sort: string,
): Product[] {
  const newProducts = subLink
    ? products.filter((item) => {
      const memo = item.memo?.split(', ');

      return memo?.includes(subLink);
    })
    : products;

  return getSortedProducts(sort, newProducts);
};
