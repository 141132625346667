import { Product } from "../types/product";
import { transliterate } from "./transliterate";

export function findProductAtUrl(
  products: Product[], url: string
): Product | null {
  const foundProduct = products
    .find((prod) => transliterate(prod.name) === url);

  return foundProduct || null;
}