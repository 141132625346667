import axios from "axios";

const apiKey = "8b29b43da69161ac9e5ab175152ae8cc";
const endPoint = 'https://api.novaposhta.ua/v2.0/json/';

const cityLimit = 11;
const cityPage = 1;
const departmentPage = 1;

export const fetchCity = async (city) => {
  try {
    const response = await axios.post(endPoint, {
      "apiKey": apiKey,
      "modelName": "Address",
      "calledMethod": "searchSettlements",
      "methodProperties": {
        "CityName": city,
        "Limit": cityLimit,
        "Page": cityPage
      }
    }, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchDepartments = async (ref, querry = '', depLimit = 100) => {
  try {
    const response = await axios.post(endPoint, {
      "apiKey": apiKey,
      "modelName": "Address",
      "calledMethod": "getWarehouses",
      "methodProperties": {
        "CityRef": ref,
        "FindByString": querry,
        "Page": departmentPage,
        "Limit": depLimit
      }
    }, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAddresses = async (ref, querry, strLimit = 30) => {
  try {
    const response = await axios.post(endPoint, {
      "apiKey": apiKey,
      "modelName": "Address",
      "calledMethod": "searchSettlementStreets",
      "methodProperties": {
        "StreetName": querry,
        "SettlementRef": ref,
        "Limit": strLimit
      }
    }, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};
