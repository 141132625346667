import './Cart.scss';

import { ButtonBack } from '../ButtonBack/ButtonBack';
import React, { useContext, useMemo } from 'react';
import { ShopContext } from '../../ShopContext';
import { CartItem } from './CartItem';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getMainPrice } from '../../helpers/getMainPrice';
import { useNavigate } from 'react-router-dom';
import { PAGES } from '../../setting/setting';
import { getTotalText } from '../../helpers/getTotalText';
import { scrollToTop } from '../../helpers/scrollToTop';


export const Cart: React.FC = () => {
  const { cart } = useContext(ShopContext);
  const navigate = useNavigate();

  const goToPage = (page: string) => {
    navigate(`/${page}`);
    scrollToTop();
  }

  const handleBackLink = () => {
    navigate(-1);
  };

  const visibleCartProducts = useMemo(() => {
    return cart.sort((a, b) => a.name.localeCompare(b.name));
  }, [cart]);

  const totalPrice = useMemo(() => (
    cart.map(p => (getMainPrice(p.prices)?.Price || 0) * (p.quantity || 1)).reduce((sum, p) => sum + p, 0)
  ), [cart]);

  const totalItems = useMemo(() => (
    cart.map(p => (p.quantity || 1)).reduce((sum, p) => sum + p, 0)
  ), [cart]);

  

  const totalText = getTotalText(totalItems);

  const isCartEmpty = cart.length <= 0;

  return (
    <div className="cart">
      <div className="cart__top">
        <div className="cart__nav">
          <ButtonBack />
        </div>
        <h1 className="cart__title title">{PAGES.CART_TITLE}</h1>
        <div className="cart__nav"></div>
      </div>
      {isCartEmpty && (
        <div className="page__col page__col--center">
          <p className="page__text">{PAGES.CART_EMPTY}</p>
          <button
            className="page__back link"
            onClick={handleBackLink}
          >
            {PAGES.BACK_TO_PRODUSCT}
          </button>
        </div>
      )}
      {!isCartEmpty && (<TransitionGroup className="cart__list">
        {visibleCartProducts.map((item) => (
          <CSSTransition
            key={item.id}
            timeout={300}
            classNames="cart__item-transition"
            enter={false}
          >
            <CartItem item={item} />
          </CSSTransition>
        ))}
      </TransitionGroup>)}
      {!isCartEmpty && (<div className="cart__action">
        <div className="cart__total">
          <p className="cart__total-info">{`Усього ${totalItems} ${totalText} на суму ${totalPrice} грн`}</p>
        </div>
        <button
          className="cart__button-order button button--order"
          onClick={() => {
            goToPage('order');
          }}
        >
          Оформити замовлення
        </button>
        <button
          className="cart__button-back link"
          onClick={handleBackLink}
        >
          Продовжити покупки
        </button>
      </div>)}
    </div>
  );
};
