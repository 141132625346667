import React from "react";
import cn from 'classnames';
import { Payments } from "../../types/payments";

type Props = {
  payment: Payments,
  onPayment: (v: Payments) => void;
}

export const OrderPayments: React.FC<Props> = ({
  payment,
  onPayment,
}) => {
  return (
    <div id="payments" className="order__section">
      <h2 className="order__subtitle subtitle">Оплата</h2>
      <div className="order__select">
        <button
          className={cn('order_payment button button--payment', {
            'button--payment--active': payment === 'onCard'
          })}
          onClick={() => {
            onPayment('onCard');
          }}
        >
          Карткою онлайн
        </button>
        <button
          className={cn('order_payment button button--payment', {
            'button--payment--active': payment === 'onBankAccaunt'
          })}
          onClick={() => {
            onPayment('onBankAccaunt');
          }}
        >
          Переказ на рахунок ФОП
        </button>
      </div>
    </div>
  );
};