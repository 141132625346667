import React from 'react';
import { Order } from './types/order';
import { ChangeType, Product } from './types/product';

type State = {
  cart: Product[];
  favourites: Product[];
  order: Order | null;
  addToFavourites: (v: number) => void;
  addToCart: (v: number) => void;
  removeFromFavourites: (v: number) => void;
  removeFromCart: (v: number) => void;
  clearCart: () => void,
  addOrder: (v: Order) => void;
  changeValueInCart: (t: ChangeType, id: number) => void;
};

const initialState: State = {
  order: null,
  cart: [],
  favourites: [],
  addToCart: () => {},
  addToFavourites: () => {},
  removeFromCart: () => {},
  changeValueInCart: () => {},
  removeFromFavourites: () => {},
  clearCart: () => {},
  addOrder: () => {},
};

export const ShopContext = React.createContext(initialState);
