import './ProductCard.scss';
import React from "react";
import { MAIN_PRICE_ID, MAIN_CURRENCY, BASE_PRODUCTS_URL, DEFOULT_IMAGE_DATA } from "../../setting/setting";
import { Product } from "../../types/product";

import { ButtonAddToCart } from '../ButtonAddToCart/ButtonAddToCart';
import { transliterate } from '../../helpers/transliterate';
import { ButtonAddToFavourites } from '../ButtonAddToFavourites/ButtonAddToFavourites';

type Props = {
  product: Product;
};

export const ProductCard: React.FC<Props> = ({ product }) => {
  const mainImage = product.images.length > 0 ? product.images[0] : DEFOULT_IMAGE_DATA;
  const mainPrice = product.prices.find((price) => price.PriceType === MAIN_PRICE_ID)?.Price;
  const url = transliterate(product.name);

  return (
    <div className="product">
      <a
        href={`../${BASE_PRODUCTS_URL}/${url}`}
        className="product__image-link"
      >
        <img className="product__image" src={mainImage.Url} alt={product.name} />
      </a>
      <a
        href={`../${BASE_PRODUCTS_URL}/${url}`}
        className="product__info"
      >
        <h3 className="product__name">{product.name}</h3>
        <div className="product__prices">
          <span className="product__price">{`${mainPrice || ''} ${MAIN_CURRENCY}`}</span>
        </div>
      </a>
      <div className="product__actions">
        <ButtonAddToCart classList={['product__button']} productId={product.id} />
        <ButtonAddToFavourites classList={['product__fav']} productId={product.id} />
      </div>
    </div>
  );
}