import { Product } from "../types/product";

export function searchProducts(products: Product[], query: string, slice: number): Product[] {
  if (query.length <= 1) {
    return [];
  };

  const fineQuery = query.trim().toLowerCase();
  const newFoundProducts = products.filter((product) => {
    const productName = product.name.trim().toLowerCase();

    return productName.includes(fineQuery) || product.id.toString() === query;
  })

  if (slice === 0) {
    return newFoundProducts;
  }

  if (newFoundProducts.length > slice) {
    return slice ? newFoundProducts.slice(0, slice) : newFoundProducts;
  }

  return newFoundProducts;
};