import React, { useEffect, useState } from 'react';
import { Product } from '../../types/product';
import { ProductCard } from '../ProductCard/ProductCard';
import './ProductCardSlider.scss';

import { ReactComponent as Right } from '../../img/icons/chevron_right.svg';
import { ReactComponent as Left } from '../../img/icons/chevron_left.svg';
import { useSwipeable } from 'react-swipeable';

type Props = {
  products: Product[];
}

const SLIDES_TO_SCROLL = 1;

export const ProductCardSlider: React.FC<Props> = ({ products }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [itemWidth, setItemWidth] = useState(getItemWidth(screenWidth));
  const [sliderWidth, setSliderWidth] = useState(getSliderWidth(screenWidth));
  const [translate, setTranslate] = useState(0);

  function getItemWidth(fullWidth: number): number {
    let padding = 20;
    let itemsCount = 2;
    if (fullWidth >= 768) {
      padding = 30;
      itemsCount = 3;
    }
    if (fullWidth >= 1440) {
      padding = 40;
      itemsCount = 4;
    }

    return (fullWidth - (padding * 2) - (padding * (itemsCount - 1))) / itemsCount;
  };

  function getSliderWidth(fullWidth: number): number {
    let padding = 20;
    if (fullWidth >= 768) {
      padding = 30;
    }
    if (fullWidth >= 1440) {
      padding = 40;
    }

    return fullWidth - (padding * 2);
  };

  function getProductsCount() {
    let count = 2;
    if (screenWidth >= 768) {
      count = 3;
    }
    if (screenWidth >= 1440) {
      count = 4;
    }

    return count;
  }

  function getPadding() {
    let padding = 20;
    if (screenWidth >= 768) {
      padding = 30;
    }
    if (screenWidth >= 1440) {
      padding = 40;
    }

    return padding;
  }

  // function getTransformParam(fullWidth: number): number {
  //   return 2;
  // }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const newItemWidth = getItemWidth(screenWidth);
    const newSliderWidth = getSliderWidth(screenWidth);
    setItemWidth(newItemWidth);
    setSliderWidth(newSliderWidth);
  }, [screenWidth]);

  const handleNext = () => {
    const productToView = getProductsCount();
    const padding = getPadding();
    const maxTranslate = -((itemWidth + padding) * (products.length - productToView));
    const newTranslate = translate - (SLIDES_TO_SCROLL * (itemWidth + padding)) < maxTranslate
      ? 0
      : translate - (SLIDES_TO_SCROLL * (itemWidth + padding));

    setTranslate(newTranslate);
  };

  const handlePrev = () => {
    const productToView = getProductsCount();
    const padding = getPadding();
    const minTranslate = 0;
    const maxTranslate = -((itemWidth + padding) * (products.length - productToView));
    const newTranslate = translate + (SLIDES_TO_SCROLL * (itemWidth + padding)) > minTranslate
      ? maxTranslate
      : translate + (SLIDES_TO_SCROLL * (itemWidth + padding));

    setTranslate(newTranslate);
  };

  const handlerSwipe = useSwipeable({
    onSwipedLeft: () => {
      handleNext();
    },
    onSwipedRight: () => {
      handlePrev();
    },
    trackMouse: true,
    delta: 20,
  })



  return (
    <div className="card-slider">
      <div className="card-slider__top">
        <h2 className="card-slider__title subtitle">Хіти продажів</h2>
        <div className="card-slider__buttons">
          <button
            type="button"
            className="card-slider__button"
            onClick={handlePrev}
          >
            <Left width={'100%'} height={'100%'} />
          </button>
          <button
            type="button"
            className="card-slider__button"
            onClick={handleNext}
          >
            <Right width={'100%'} height={'100%'} />
          </button>
        </div>
      </div>
      <div
        className="card-slider__list"
        style={{ maxWidth: `${sliderWidth}px` }}
        {...handlerSwipe}
      >
        <div className="card-slider__slides" style={{ transform: `translateX(${translate}px)` }}>
          {products.map((product) => {
            return (
              <div
                className="card-slider__item"
                key={product.id}
                style={{ width: `${itemWidth}px` }}
              >
                <ProductCard product={product} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};
